// RiskifiedBeacon.tsx
'use client'
import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

interface RiskifiedBeaconProps {
  sessionId?: string; // sessionId is now an optional prop
}

const RiskifiedBeacon: React.FC<RiskifiedBeaconProps> = ({ sessionId: propSessionId }) => {
  const [sessionId, setSessionId] = useState<string>(propSessionId || ''); // Initialize sessionId with prop value or empty string

  useEffect(() => {
    // If propSessionId is provided, use it as sessionId
    if (propSessionId) {
      setSessionId(propSessionId);
    } else {
      // If propSessionId is not provided, generate a UUIDv4
      setSessionId(uuidv4());
    }
  }, [propSessionId]); // Re-run effect when propSessionId changes

  useEffect(() => {
    const riskifiedBeaconLoad = () => {
      const storeDomain = 'ticketevolution.com'; // Set the store_domain variable

      const url =
        (document.location.protocol === 'https:' ? 'https://' : 'http://') +
        'beacon.riskified.com?shop=' +
        storeDomain +
        '&sid=' +
        sessionId; // Construct the URL

      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.async = true;
      script.src = url;

      const x = document.getElementsByTagName('script')[0];
      if (x.parentNode) {
        x.parentNode.insertBefore(script, x);
      }
    };

    window.addEventListener('load', riskifiedBeaconLoad);

    // Cleanup function to remove event listener
    return () => {
      window.removeEventListener('load', riskifiedBeaconLoad);
    };
  }, [sessionId]);

  return null;
};

export default RiskifiedBeacon;
