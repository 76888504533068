import Bugsnag from '@bugsnag/js'

Bugsnag.start({
    apiKey: process.env.NEXT_PUBLIC_BUGSNAG_KEY,
    releaseStage: process.env.NODE_ENV,
    notifyReleaseStages: ['production', 'staging'],
    onError: function (event) {
        const user = localStorage.getItem('users');
        if(user){
            let parsedUser = JSON.parse(user);
            event.setUser(parsedUser?.client_id, parsedUser?.email, `${parsedUser?.firstname} ${parsedUser?.lastname}`)
        } else{
            const buynow = localStorage.getItem('buyNow');
            if(buynow){
                const parsedBuynow = JSON.parse(buynow);
                if(parsedBuynow.client){
                event.setUser(parsedBuynow?.client?.client_id,parsedBuynow?.client?.email)
                }
            }
        }
      }
})

if(Bugsnag.isStarted()) {
    console.log('Bugsnag is started')
} else {
    console.log('Bugsnag is not started')
}


export default Bugsnag