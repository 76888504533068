"use client";
import rest from "@feathersjs/rest-client";
import { feathers } from "@feathersjs/feathers";
// import { createClient } from "microsites-backend";
import authentication from "@feathersjs/authentication-client";
import Bugsnag from '../../bugsnag.js';
// require('dotenv').config({ path: '../.env' });
// const backendLink = process.env.BACKEND_LINK;
// console.log(`API URL: ${backendLink}`);

const feathersClient = feathers();
const isBrowser = typeof window !== "undefined";
let apiUrl = "https://stage.karmatickets.ca";
let frontend_API = "https://stage.karmatickets.ca";
let domain_url = "api.sandbox.ticketevolution.com";
let hostprefix = "";
let frontendHost = "";
if (isBrowser) {
  const protocol = location.protocol;
  const port = location.port;
  const slashes = protocol.concat("//");
  const host = slashes.concat(window.location.host);
  console.log(protocol);
  console.log("host = " + JSON.stringify(host));
  console.log("hostname ==" + JSON.stringify(window.location.hostname));
  hostprefix = window.location.hostname.split(".")[0];
  frontendHost = window.location.hostname.split(".")[0];
  console.log("hostprefix value =" + JSON.stringify(frontendHost));
}
// const protocol = window.location.protocol;
// console.log(protocol);
// const protocol = location.protocol;
// console.log(protocol);
// console.log("hostprefix value =" + JSON.stringify(hostprefix));
switch (hostprefix) {
  case "kt2024":
    apiUrl = "https://kt2024.ca";
    break;
  case "stage":
    apiUrl = "https://stage.karmatickets.ca";
    break;
  case "karmatickets":
    apiUrl = "https://karmatickets.ca";
    break;
  case "www": 
    apiUrl = "https://karmatickets.ca";
    break;
  default:
    apiUrl = "http://localhost:3030";
    break;
}
switch (frontendHost) {
  case "kt2024":
    frontend_API = "https://kt2024.ca";
    break;
  case "karmatickets":
    frontend_API = "https://karmatickets.ca";
    break;
  case "www":
    frontend_API = "https://karmatickets.ca";
    break;
  case "stage":
    frontend_API = "https://stage.karmatickets.ca";
    break;
  default:
    frontend_API = "http://localhost:3000";
    break;
}
switch (frontendHost) {
  case "kt2024":
  case "www":
  case "karmatickets":
    domain_url = "api.ticketevolution.com";
    break;
  default:
    domain_url = "api.sandbox.ticketevolution.com";
    break;
}
console.log(frontend_API);
const restClient: any = rest(apiUrl);

if (isBrowser) {
  localStorage.setItem('Frontend_API', frontend_API);
  localStorage.setItem('domain_url', domain_url);
}
// Configure feathersClient only if running in a browser environment
// feathersClient.configure(restClient.fetch(window.fetch.bind(window)));
if (isBrowser) {
  feathersClient.configure(restClient.fetch(window.fetch.bind(window)));
}

feathersClient.configure(authentication({
  path: "api/v1/authentication"
}));

feathersClient.hooks({
  error(context) {
    console.log("feathersclient on error", context);
    let url = context?.error?.response?.url;
    let message = context?.error?.message;
    let code = context.error?.code;
    let data = context.data ? JSON.stringify(context.data) : '';

    Bugsnag.notify(context.error, event => {
      event.addMetadata('metadata', { url: url, code: code, message: message, data: data });
    });
    return context;
  }
});


export { feathersClient };


// // Import necessary modules
// import rest from "@feathersjs/rest-client";
// import { feathers } from "@feathersjs/feathers";
// import authentication from "@feathersjs/authentication-client";

// // Create a Feathers.js client instance
// const feathersClient = feathers();

// // Check if the code is running in a browser environment
// const isBrowser = typeof window !== "undefined";

// // Configure rest client for the server-side environment
// const restClient = isBrowser ? rest("http://localhost:3030").fetch(window.fetch.bind(window)) : undefined;

// // Configure feathersClient only if running in a browser environment
// if (isBrowser) {
//   feathersClient.configure(restClient);
//   feathersClient.configure(authentication());
// }

// // Export the configured feathersClient
// export { feathersClient };


