import { createSlice, PayloadAction } from "@reduxjs/toolkit";
interface TicketGroup {

  format: string;
  retail_price: number;
  id: number;
  url: string;
  type: string;
  row: string; // Change the type of 'row' property to string
  section: string;
  quantity: number;
  available_quantity: number;
  wholesale_price: number;
  eticket: boolean;
  instant_delivery: boolean;
  intended_for_instant_delivery: boolean;
  tevo_section_name: string;
}


interface Viewtickets {
  available_count: number;

  configuration: {
    id: string;
  };
  id: number;
  long_term_popularity_score: number;
  name: string;
  occurs_at: string;
  venue: {
    id: string;
    slug: string;
    url: string;
    slug_url: string;
    name: string;
    location: string;
    time_zone: string;
  };

}

interface BuyNowState {
    viewtickets: Viewtickets[];
    ticketGroup: TicketGroup[];
  }
  const initialState: BuyNowState = {
    viewtickets: [],
    ticketGroup: [],
  };
  
  export const buynow = createSlice({
    name: 'buynow',
    initialState,
    reducers: {
      UpdateBuyNow: (state, action: PayloadAction<BuyNowState>) => {
        state.viewtickets = action.payload.viewtickets;
        state.ticketGroup = action.payload.ticketGroup;
      },
    },
  });
    
    export const { UpdateBuyNow } = buynow.actions;
    export default buynow.reducer;
    