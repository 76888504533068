"use client"
import React, { createContext, useState, useEffect, useContext } from 'react';
import { Application } from '@feathersjs/feathers';
import auth from '@feathersjs/authentication-client';
import { feathersClient } from '@/components/connection/page';
import { feathers } from '@feathersjs/feathers';


// Initialize the Feathers application

const app: Application<any> = feathers();

// Configure authentication

app.configure(auth());

// Create context for authentication state
const AuthContext = createContext<{ user: any | null; loading: boolean }>({
  user: null,
  loading: true,
});

// AuthProvider component manages authentication state
export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<any | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Function to authenticate user on component mount
    const authenticate = async () => {
      try {
        const data=localStorage.getItem("feathers-jwt");
        console.log("data",data);
        if(data){
            feathersClient.authenticate({
              strategy: 'jwt',
              accessToken: localStorage.getItem("feathers-jwt")
            }).then(async(res:any) => {
              console.log("response after auth", res);
            //   if(res && res.users){
            //     const data = res.users
                
            //   }
            }).catch((err:any) => {
              console.log("No user exists", err);
            })
          } // Set authenticated user
      } catch (error) {
        setUser(null); // Handle authentication error
      } finally {
        setLoading(false); // Set loading state to false
      }
    };

    authenticate(); 

  }, []); 

  // Provide authentication context to children components
  return (
    <AuthContext.Provider value={{ user, loading }}>
      {children}
    </AuthContext.Provider>
  );
};

// Custom hook to consume authentication context
export const useAuth = () => useContext(AuthContext);
